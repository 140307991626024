import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactLinks from "../components/contact-links"

import WaveIcon from "../../content/assets/icons/waving-hand.svg"

export default function ContactPage({ data, location }) {
  const siteTitle = data.site.siteMetadata?.title
  const author = data.site.siteMetadata?.author?.name

  return (
    <Layout location={location} title={siteTitle} author={author}>
      <SEO title="contact page" />
      <ContactLinks />
      <h3>About Katie</h3>
      <p>
        <WaveIcon /> I’m a proud Canadian, hailing from Vancouver, British
        Columbia, living the glamorous expatriot life in Portland, Oregon. I’m a
        an enginering manager by profession, but as you can probably tell by the
        mishmash of stuff on this blog, I am interested in a lot of different
        things. I love to read, and my obsessions are personal productivity,
        psychology, sociology, and history. And philosophy. And art. I love to
        travel, because it forces me to be uncomfortable — growth only happens
        through discomfort — which is why I am also always learning new
        technology stacks.
      </p>
      <p>
        In the beforetimes (pre-coronavirus), I was an avid traveler and general
        enjoyer of food, wine, arts, and culture. While we are stuck in the
        hell-mouth, I am reading a ton, starting to explore new technologies
        again (looking at you, Gatsby), and have no excuses for neglecting this
        site.
      </p>
      <h3>Hiring inquiries</h3>
      <p>
        I am not actively looking for new job opportunities, but I am open to
        chat if you are looking for an engineering manager and you represent a
        company that does real good in the world.
      </p>
      <h4>Relocation</h4>
      <p>
        If I work in the US you will not likely get me to budge from Portland.
        If you represent a company that is open to helping me relocate to the
        EU, we should talk.
      </p>
      <h3>Where to find me</h3>
      <p>
        I am not generally on social media anymore, but I do maintain a LinkedIn
        account to keep tabs on my professional connections. You can always
        reach me via email, though I offer no SLA regarding promptness of
        response.
      </p>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        author {
          name
        }
        title
      }
    }
  }
`
